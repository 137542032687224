.footer {
  position: fixed;
  bottom: calc(env(safe-area-inset-bottom) + 30px);
  left: 0;
  text-align: center;
  right: 0;
  // color: #fff;

  .copyright {
    font-weight: lighter;
    font-size: 10px;
  }

  .name {
    margin-left: 1ex;
  }

  .links a:not(:last-child):after {
    position: relative;
    content: "·";
    left: 3px;
    margin-right: 5px;
  }

  .links {
    margin-left: 1ex;
    user-select: none;

    a {
      text-decoration: none;
      color: #b78775;
      font-weight: 400;
      font-family: Arial, Helvetica, sans-serif;
    }
  }
}
