@font-face {
  font-family: "happy";
  src: url("https://cdn.jsdelivr.net/gh/akiq2016/assets/fonts/zkhappy.ttf")
    format("truetype");
  font-display: swap;
}

$fontFamily: "happy", "Source Sans Pro", "Helvetica Neue", Arial, sans-serif;

.App {
  text-align: center;
  font-family: $fontFamily;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
}

.App-title {
  position: relative;
  font-weight: 600;

  &::after {
    position: absolute;
    content: "";
    background-color: #a1d3f1;
    height: 30%;
    width: 110%;
    bottom: 20%;
    right: -5%;
    z-index: -1;
  }
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.eatwatBox {
  position: relative;
  margin-top: 10px;
  width: 300px;
  height: 350px;
  background-size: cover;

  .watermark {
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    z-index: 1;
    mix-blend-mode: overlay;
    font-size: 12px;
    font-family: sans-serif;
    display: flex;
    align-items: flex-end;
    justify-content: center;
  }

  .eatwatBox-item {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    opacity: 0;

    .name {
      font-size: 22px;
      line-height: 50px;
    }
    .img {
      width: 100%;
      height: 300px;
    }
  }
}

.btn {
  user-select: none;
  background: url("./assets/btn.svg") no-repeat;
  background-size: 90px;
  background-position: center;
  height: 50px;
  line-height: 48px;
  width: 90px;
  margin: 15px auto 0;
  color: #333;
  font-size: 16px;
  font-weight: normal;
}
